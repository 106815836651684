export const ContactObj = {
  id: "contact",
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "",
  headline: "",
  description: "",
  buttonLabel: "Submit",
  imgStart: true,
  img: "/images/svg-2.svg",
  alt: "Contact",
  dark: false,
  primary: false,
  darkText: true,
  fontBig: false,
  big: true,
};
