import Home from "./pages";
import React from "react";

function App() {
  return (
    <>
      <Home />
    </>
  );
}

export default App;
