import React, { useState } from "react";
/* eslint-disable */
import {
  ContactContainer,
  ContactWrapper,
  ContactRow,
  Column1,
  Column2,
  ImgWrap,
  Img,
  Label,
  InputName,
  InputEmail,
  InputPhone,
  FormWrapper,
  NameWrapper,
  EmailWrapper,
  PhoneWrapper,
  MessageWrapper,
  SubjectWrapper,
  Option,
  TextBox,
  ContactH1,
  FormInputs,
  SelectOpt,
  ContactBtnWrap,
  ContactButton,
  ErrorDiv
} from "./ContactSection";
import emailjs from "emailjs-com";

const ContactSection = ({ lightBg, id, imgStart, img, alt }) => {
  const initialState = {
    name: "",
    email: "",
    phone: "",
    message: "",
    subject: "",
  };


  //States
  const [errorMessage, setErrorMessage] = useState('');
  const [errorName, setErrorName] = useState(false);
  const [errorText, setErrorText] = useState(false);
  const [errorPhone, setErrorPhone] = useState(false);
  const [errorSubject, setErrorSubject] = useState(false);
  const [error, setError] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);
  const [state, setState] = useState(initialState);

  //handling inputs
  const handleInput = (e) => {
    const inputName = e.currentTarget.name;
    const value = e.currentTarget.value;

    setState((prev) => ({ ...prev, [inputName]: value }));
  };

  const { email, subject, message } = state;

  const handleSubmit = (e) => {
    e.preventDefault();

    //regex for verifying the email pattern
    const regex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    
    //check if everything is set
    if (regex.test(email) && email !== "" && message !== "" && subject !== "") {
      emailjs.send(
        "service_ua9ryhs",
        "template_49jff6p",
        state,
        "user_LogpxuzXlTKqwJZ5PHyJm"
      );

      //Reseting to initial states
      setState(initialState);
      setErrorEmail(false);
      setErrorSubject(false);
      setErrorName(false);
      setErrorPhone(false);
      setErrorText(false);
      setError(false);
      setErrorMessage('');

      //if any input is invalid show wheres the error
    } else {
      if (!regex.test(email) || email === ""){
        console.log(regex.test(email));
        setErrorEmail(true);
      }
      if(state.subject === ""){
        setErrorSubject(true);
      }
      if(state.name === ""){
        setErrorName(true);
      }
      if(state.phone === ""){
        setErrorPhone(true);
      }
      if(state.message === ""){
        setErrorText(true);
      }
      setError(true);
      setErrorMessage("Please fill out all fields!");
    }
  };

  return (
    <>
      <ContactContainer lightBg={lightBg} id={id}>
        <ContactWrapper>
          <ContactRow imgStart={imgStart}>
            <Column1>
              <FormWrapper>
                <ContactH1>&bull; Keep in Touch &bull;</ContactH1>
                <FormInputs>
                  <NameWrapper>
                    <Label>
                      <InputName
                        type="text"
                        placeholder="Name"
                        name="name"
                        value={state.name}
                        onChange={handleInput}
                        required
                        errorName={errorName}
                      />
                    </Label>
                  </NameWrapper>
                  <EmailWrapper>
                    <Label>
                      <InputPhone
                        type="text"
                        placeholder="Phone"
                        name="phone"
                        value={state.phone}
                        onChange={handleInput}
                        required
                        errorPhone={errorPhone}
                      />
                    </Label>
                  </EmailWrapper>
                  <PhoneWrapper>
                    <Label>
                      <InputEmail
                        type="email"
                        placeholder="E-mail"
                        name="email"
                        value={state.email}
                        onChange={handleInput}
                        required
                        errorEmail={errorEmail}
                      />
                    </Label>
                  </PhoneWrapper>
                  <SubjectWrapper>
                    <SelectOpt
                      placeholder="Subject of the message"
                      name="subject"
                      defaultValue={"DEFAULT"}
                      onChange={handleInput}
                      required
                      errorSubject={errorSubject}
                    >
                      <Option disabled hidden value="DEFAULT">
                        Subject
                      </Option>
                      <Option value="I would like to start a project">
                        I'd like to start a project
                      </Option>
                      <Option value="I would like to ask a question">
                        I'd like to ask a question
                      </Option>
                      <Option value="I would like to make a proposal">
                        I'd like to make a proposal
                      </Option>
                    </SelectOpt>
                  </SubjectWrapper>
                  <MessageWrapper>
                    <Label>
                      <TextBox
                        name="message"
                        placeholder="I'd like to chat about"
                        cols="30"
                        rows="5"
                        value={state.message}
                        onChange={handleInput}
                        required
                        errorText={errorText}
                      ></TextBox>
                    </Label>
                  </MessageWrapper>
                <ErrorDiv error={error}> 
                  {errorMessage}
                </ErrorDiv>
                </FormInputs>
                <ContactBtnWrap>
                  <ContactButton type="submit" onClick={handleSubmit}>
                    Send Message
                  </ContactButton>
                </ContactBtnWrap>
              </FormWrapper>
            </Column1>
            <Column2>
              <ImgWrap>
                <Img src={img} alt={alt} />
              </ImgWrap>
            </Column2>
          </ContactRow>
        </ContactWrapper>
      </ContactContainer>
    </>
  );
};

export default ContactSection;
