export const homeObjOne = {
  id: "about",
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "About Me",
  headline: "Learning and Teamwork!",
  description:
    "I am eager to learn new technologies and achieve new goals. I also believe that teamwork is the key to success as even if you are talented you can only achieve your full potential by helping others.",
  buttonLabel: "MORE INFO? CONTACT ME!",
  imgStart: false,
  img: "/images/svg-1.svg",
  alt: "Profile",
  dark: false,
  primary: false,
  darkText: true,
};


//
