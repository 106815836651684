import React, { useState } from "react";
import HeroSection from "../components/HeroSection";
import { BrowserRouter as Router } from "react-router-dom";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import InfoSection from "../components/InfoSection";
import { homeObjOne } from "../components/InfoSection/Data";
import { ContactObj } from "../components/ContactSection/ContactObj";
import PortfolioSection from "../components/Portfolio";
import ContactSection from "../components/ContactSection";
import Footer from "../components/Footer";

const Home = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Router>
        <Sidebar isOpen={isOpen} toggle={toggle} />
        <Navbar toggle={toggle} />
        <HeroSection />
        <InfoSection {...homeObjOne} />
        <PortfolioSection />
        <ContactSection {...ContactObj} />
        <Footer />
      </Router>
    </>
  );
};

export default Home;
