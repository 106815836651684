import styled from "styled-components";

export const FooterContainer = styled.footer`
  background-color: #101522;
`;
export const FooterWrap = styled.div`
  padding: 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1100px @media screen and (max-width: 820px) {
    margin-top: 120px;
  }
`;

export const SocialMedia = styled.div`
  max-width: 1000px;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

//   margin-top: 48px;
export const SocialMediaWrap = styled.div`
  display: flex;
  align-item: center;
  max-width: 1100px;

  @media screen and (max-width: 820px) {
    flex-direction: column;
  }
`;
// export const SocialLogo = styled.label(Link)`
//     color: #fff;
//     justify-self: start;
//     cursor: pointer;
//     text-decoration: none;
//     font-size: 1.5rem;
//     align-items: center;
//     margin-bottom: 16px;
//     font-weight: bold;
// `
export const WebsiteRights = styled.small`
  color: #fff;
  margin-top: 20px;
  margin-bottom: 5px;
  text-align:center;
`;
export const SocialIcons = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 180px;

  @media screen and (max-width: 820px) {
    flex-direction: column;
  }
`;
export const SocialIconLink = styled.a`
  color: #fff;
  font-size: 24px;
`;
