import styled from "styled-components";

export const PortfolioContainer = styled.div`
  height: 900px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #101522;

  .rec.rec-dot {
    background: #fff;
    border: 2px #333 solid #01bf71;
    border-radius: 10px;
    box-shadow: 0 0 1px 3px #fff;
    transition: all 0.2 ease-in-out;
  }
  .rec.rec-dot_active {
    background: #01bf71;
    border: 2px #333 solid #01bf71;
    box-shadow: 0 0 1px 3px #01bf71;
    transition: all 0.2 ease-in-out;
  }

  .rec.rec-arrow-left:hover:enabled,
  .rec.rec-arrow-right:hover:enabled {
    background-color: #01bf71;
    box-shadow: 0 0 1px 3px #01bf71;
    transition: all 0.2 ease-in-out;
  }

  @media screen and (max-width: 480px) {
    height: 800px;
    width: 100%;
  }
`;

export const PortfolioWrapper = styled.div`
  max-width: 1000px;
  height: 650px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  grid-gap: 16px;
  padding: 0 50px;

  @media screen and (max-width: 908px) {
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (max-width: 550px) {
    grid-template-columns: 1fr;
    padding: 0 20px;
  }
`;

export const CardWrapper = styled.div`
  background: #101522;
  height: 525px;
  width: 450px;
  align-items: center;
  padding-top: 25px;
  border-radius: 10px;

`

export const PortfolioCard = styled.div`
  background: #fff;
  display: flex;
  height: 500px;
  width: 400px;
  margin-left: 25px;
  border-radius: 10px;
  column-gap: 10px;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 2px 5px 3px #fff; 
  transition: all 0.2 ease-in-out;
  text-decoration: none;

  &.visited {
    color: black;
  }

  &:hover {
    transition: all 0.2 ease-in-out;
    transform: scale(1.05);
  }

  @media screen and (max-width: 420px) {
    width: 100%;
    height: 200px;
  }

  @media screen and (max-width: 550px) {
    width: 300px;
    height: 400px;
  }
`;

export const PortfolioIcon = styled.img`
  height: 300px;
  padding: 5px;
  width: 400px;
  margin-bottom: 10px;
  

  @media screen and (max-width: 420px) {
    width: 100%;
    height: 200px;
  }
  @media screen and (max-width: 550px) {
    width: 300px;
    height: 400px;
  }
`;
export const PortfolioH1 = styled.h1`
  font-size: 2.5rem;
  color: #fff;
  margin-bottom: 64px;

  @media screen and (max-width: 420px) {
    font-size: 1.8rem;
  }
`;
export const PortfolioH2 = styled.h2`
  font-size: 1rem;
  margin-bottom: 10px;
  margin: 20px auto 20px auto;
  padding: 0 20px 0 20px;

  @media screen and (max-width: 420px) {
    font-size: 0.8rem;
  }
`;
export const PortfolioP = styled.p`
  font-size: 1rem;
  text-align: center;
  padding: 0 10px 0 10px;
  font-weight: 100;

  @media screen and (max-width: 520px) {
    font-size: 0.7rem;
    margin: 0 auto 0 auto;
    width: 90%;
    padding: 0;
  }
`;

export const Item = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 450px;
  width: 100%;
  background-color: #fff;
  color: #fff;
  margin: 15px;
  font-size: 4em;
`;

export const SkillsWrapper = styled.div`
  display: flex;
  height: 30px;
  justify-content: space-evenly;
  border-top: 1px solid #eeeeee;
  padding-top: 3px;
  border-bottom: 1px solid #eeeeee;
  align-items: center;
  width: 400px;

  @media screen and (max-width: 768px) {
    justify-content: center;
    justify-content: space-evenly;
  }

  @media screen and (max-width: 420px) {
    width: 100%;
    height: 200px;
  }
  @media screen and (max-width: 550px) {
    width: 300px;
    justify-content: space-evenly;
    height: 400px;
  }
`;
export const CardDescWrap = styled.div`
  align-items: center;
  border-radius: 5px;
  width: 400px;
  padding-bottom: 10px;
  text-align: center;

  @media screen and (max-width: 768px) {
    justify-content: center;
  }
  @media screen and (max-width: 480px) {
    justify-content: center;
    padding-top: 20px;
  }
`;

export const PortfolioViewProject = styled.a`
  text-align: center;
  text-decoration: none;
  font-size: 1.2rem;
  color: black;
  font-weight: 600;

  &:hover {
    color: #01bf71;
    cursor: pointer;
  }

  @media screen and (max-width: 480px) {
    font-size: 0.8rem;
  }
`;

export const SkillsIconLink = styled.a`
  font-size: 24px;
`;

export const DescriptionWrapper = styled.div`
  height: 209px;
  text-align: center;

  @media screen and (max-width: 400px) {
    width: 100%;
    height: 100px;
  }
`;
