import styled from "styled-components";

export const ContactContainer = styled.div`
color: #fff;
background: ${({ lightBg }) => (lightBg ? "#f9f9f9" : "#010606")};
font-family: 'Encode Sans Expanded', sans-serif;
    
    @media screen and (max-width: 768px){
      padding 200px 0;
    }

    
    `;

export const ContactWrapper = styled.div`
  display: grid;
  z-index: 1;
  height: 860px;
  width: 100%;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 24px;
  justify-content: center;
`;
// grid-auto-columns: minmax(auto, 1fr);

export const ContactRow = styled.div`
  display: grid;
  column-gap: 70px;
  align-items: center;
  grid-template-areas: ${({ imgStart }) =>
    imgStart ? `'col2 col1'` : `'col1 col2'`};

  @media screen and (max-width: 768px) {
    grid-template-areas: ${({ imgStart }) =>
      imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`};
  }
`;
export const Column1 = styled.div`
    margin-bottom 15px;
    padding: 0 15px;
    grid-area: col1;
    column-gap: 50px;

    @media screen and (max-width: 768px) {
      margin-right: 30px;
`;

export const Column2 = styled.div`
    margin-bottom 15px;
    padding: 0 15px;
    grid-area: col2;
    column-gap: 50px;
`;

export const ImgWrap = styled.div`
  max-width: 555px;
  height: 100%;
`;
export const Img = styled.img`
  width: 100%;
  margin: 0 0 10px 0;
  padding-right: 0;
`;

export const FormWrapper = styled.div`
  border: solid 3px #474544;
  max-width: 768px;
  border-radius: 10px;

  @media screen and (max-width: 768px) {
    margin: 0 0 10px 0;
    width: 95%;
    height: fit-content;
  }
  @media screen and (max-width: 480px) {
    height: 480px;
  }
`;
export const EmailWrapper = styled.div`
  float: right;
  width: 45%;
`;
export const ContactH1 = styled.h1`
  color: #474544;
  font-size: 32px;
  font-weight: 700;
  letter-spacing: 7px;
  text-align: center;

  @media screen and (max-width: 480px) {
    font-size: 18px;
  }
`;
//   text-transform: uppercase;
export const FormInputs = styled.form`
  padding: 37.5px;
  padding-bottom: 0;
  margin-top: 50px;
  margin-bottom: 0;

  @media screen and (max-width: 768px) {
    padding: 10px;
    margin: 0px;
  `;

//   text-transform: uppercase;
export const InputName = styled.input`
  background: none;
  border: none;
  border-bottom: ${({ errorName }) => (errorName ? "solid 2px #F86B6D" : "solid 2px #474544")};
  color: #474544;
  font-size: 1em;
  font-weight: 400;
  letter-spacing: 1px;
  margin: 0em 0 1.875em 0;
  padding: 0 0 0.875em 0;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;

  &:focus {
    outline: none;
    padding: 0 0 0.875em 0;
  }
`;
// text-transform: uppercase;
export const InputPhone = styled.input`
  background: none;
  border: none;
  border-bottom: ${({ errorPhone }) => (errorPhone ? "solid 2px #F86B6D" : "solid 2px #474544")};
  color: #474544;
  font-size: 1em;
  font-weight: 400;
  letter-spacing: 1px;
  margin: 0em 0 1.875em 0;
  padding: 0 0 0.875em 0;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;

  &:focus {
    outline: none;
    padding: 0 0 0.875em 0;
  }
`;
// text-transform: uppercase;
export const InputEmail = styled.input`
  background: none;
  border: none;
  border-bottom: ${({ errorEmail }) => (errorEmail ? "solid 2px #F86B6D" : "solid 2px #474544")};
  color: #474544;
  font-size: 1em;
  font-weight: 400;
  letter-spacing: 1px;
  margin: 0em 0 1.875em 0;
  padding: 0 0 0.875em 0;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;

  &:focus {
    outline: none;
    padding: 0 0 0.875em 0;
  }
`;
// text-transform: uppercase;
export const TextBox = styled.textarea`
  background: none;
  border: none;
  border-bottom: ${({ errorText }) => (errorText ? "solid 2px #F86B6D" : "solid 2px #474544")};
  color: #474544;
  font-size: 1em;
  font-weight: 400;
  letter-spacing: 1px;

  padding: 0 0 0.875em 0;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  line-height: 150%;
  height: 150px;
  resize: none;
  width: 100%;

  &:focus {
    outline: none;
    padding: 0 0 0.875em 0;
  }
`;
export const Label = styled.label`
  font-size: 1.2rem;

  @media screen and (max-width: 480px) {
    font-size: 0.8rem;
  }
`;
// text-transform: uppercase;
export const SelectOpt = styled.select`
  background: none;
  border: none;
  border-bottom: ${({ errorSubject }) => (errorSubject ? "solid 2px #F86B6D" : "solid 2px #474544")};
  color: #474544;
  font-size: 1em;
  font-weight: 400;
  letter-spacing: 1px;
  margin: 0em 0 1.875em 0;
  padding: 0 0 0.875em 0;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
`;

export const MessageWrapper = styled.div`
  float: none;
  margin-bottom: 0;
`;

export const NameWrapper = styled.div`
  float: left;
  width: 45%;
`;

export const SubjectWrapper = styled.div`
  width: 100%;
`;
export const PhoneWrapper = styled.div`
  width: 100%;
`;

export const ErrorDiv = styled.div`
  margin-bottom: 15px;
  text-align: center;
  width: 100%;
  line-height: 40px;
  border-radius: 5px;
  height: 40px;
  vertical-align: middle;
  color: ${({ error }) => (error ? "#F86B6D" : "#")};
`;
export const Option = styled.option``;

export const ContactBtnWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 0;
`;

export const ContactButton = styled.button`
  border-radius: 50px;
  background: ${({ primary }) => (primary ? "#01BF71" : "#010606")};
  white-space: nowrap;
  padding: ${({ big }) => (big ? "14px 48px" : "12px 30px")};
  color: ${({ dark }) => (dark ? "#010606" : "#fff")};
  font-size: ${({ fontBig }) => (fontBig ? "20px" : "16px")};
  outline: none;
  border: none;
  cursor: pointer;
  display: inline-block;
  justify-content: center;
  outline: none;
  align-items: center;
  transition: all 0.2s ease-in-out;
  text-transform: uppercase;
  font-weight: bold;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: ${({ primary }) => (primary ? "#fff" : "#01BF71")};
  }
`;
