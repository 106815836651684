import React from "react";
import { DiJavascript1, DiReact, DiHtml5, DiJava, DiAndroid, DiFirebase, DiScrum} from "react-icons/di";
import {FaGithub} from "react-icons/fa";
import {SiSqlite} from "react-icons/si";
import {BsBootstrap} from "react-icons/bs";
import { IconContext } from "react-icons/lib";
import {
  PortfolioContainer,
  PortfolioCard,
  PortfolioIcon,
  PortfolioH1,
  CardWrapper,
  PortfolioH2,
  PortfolioP,
  SkillsWrapper,
  PortfolioViewProject,
  SkillsIconLink,
  CardDescWrap,
  DescriptionWrapper,
} from "./PortfolioElements";
import Carousel from "react-elastic-carousel";

const PortfolioSection = () => {
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 1 },
    { width: 810, itemsToShow: 2 },
    { width: 1210, itemsToShow: 3 },
    { width: 1610, itemsToShow: 3 },
  ];

  return (
    <>
      <PortfolioContainer id="portfolio">
        <PortfolioH1>Projects</PortfolioH1>
        <Carousel breakPoints={breakPoints}>
        <CardWrapper>
          <PortfolioCard>
            <PortfolioIcon src="/images/tibiaWarAPI.gif" />
            <SkillsWrapper>
              <IconContext.Provider value={{ color: "#bc9110" }}>
                <SkillsIconLink aria-label="JavaScript">
                  <DiJavascript1 />
                </SkillsIconLink>
              </IconContext.Provider>
              <IconContext.Provider value={{ color: "#5accde" }}>
                <SkillsIconLink aria-label="React">
                  <DiReact />
                </SkillsIconLink>
              </IconContext.Provider>
              <IconContext.Provider value={{ color: "#e28522" }}>
                <SkillsIconLink aria-label="Html">
                  <DiHtml5 />
                </SkillsIconLink>
              </IconContext.Provider>
              <IconContext.Provider value={{ color: "#e28522" }}>
                <SkillsIconLink aria-label="Bootstrap">
                  <BsBootstrap />
                </SkillsIconLink>
              </IconContext.Provider>
            </SkillsWrapper>
            <DescriptionWrapper>
              <PortfolioH2>Tibia Enemies Online Website</PortfolioH2>
              <PortfolioP>
                Project made to test my skills using third party API's. Players of the game Tibia could search for their "enemies" online by using this website.
              </PortfolioP>
            </DescriptionWrapper>
            <CardDescWrap>
              <PortfolioViewProject href="https://github.com/carrilhorafael57/tibiawarapi"> 
              View Project  <FaGithub/>
              </PortfolioViewProject>
            </CardDescWrap>
          </PortfolioCard>
          </CardWrapper>

          <CardWrapper>
          <PortfolioCard>
            <PortfolioIcon src="/images/punchApp.gif" />
            <SkillsWrapper>
              <IconContext.Provider value={{ color: "#bc9110" }}>
                <SkillsIconLink aria-label="Java">
                  <DiJava />
                </SkillsIconLink>
              </IconContext.Provider>
              <IconContext.Provider value={{ color: "#5accde" }}>
                <SkillsIconLink aria-label="Android">
                  <DiAndroid />
                </SkillsIconLink>
              </IconContext.Provider>
              <IconContext.Provider value={{ color: "#e28522" }}>
                <SkillsIconLink aria-label="Firebase">
                  <DiFirebase />
                </SkillsIconLink>
              </IconContext.Provider>
              <IconContext.Provider value={{ color: "#e28522" }}>
                <SkillsIconLink aria-label="Scrum">
                  <DiScrum />
                </SkillsIconLink>
              </IconContext.Provider>
            </SkillsWrapper>
            <DescriptionWrapper>
              <PortfolioH2>Punch App</PortfolioH2>
              <PortfolioP>
                This was a project developed using android technology. It was built in Java and Firebase as database. Also developed in a Scrum environment.
              </PortfolioP>
            </DescriptionWrapper>
            <CardDescWrap>
              <PortfolioViewProject href="https://github.com/carrilhorafael57/Mobile-PunchApp" target="_blank">
                   View Project  <FaGithub/>
              </PortfolioViewProject>
            </CardDescWrap>
          </PortfolioCard>
          </CardWrapper>

          <CardWrapper>
          <PortfolioCard>
            <PortfolioIcon src="/images/ReadGrowApp.gif" />
            <SkillsWrapper>
              <IconContext.Provider value={{ color: "#bc9110" }}>
                <SkillsIconLink aria-label="Java">
                  <DiJava />
                </SkillsIconLink>
              </IconContext.Provider>
              <IconContext.Provider value={{ color: "#5accde" }}>
                <SkillsIconLink aria-label="Android">
                  <DiAndroid />
                </SkillsIconLink>
              </IconContext.Provider>
              <IconContext.Provider value={{ color: "#e28522" }}>
                <SkillsIconLink aria-label="SQLite">
                  <SiSqlite />
                </SkillsIconLink>
              </IconContext.Provider>
            </SkillsWrapper>
            <DescriptionWrapper>
              <PortfolioH2>ReadGrow App</PortfolioH2>
              <PortfolioP>
                This was a project developed using android technology. It was built in Java and SQLite as database. CRUD features and also option to users send messages.
              </PortfolioP>
            </DescriptionWrapper>
            <CardDescWrap>
              <PortfolioViewProject href="https://github.com/carrilhorafael57/Mobile-BookShareApp" target="_blank">
                   View Project  <FaGithub/>
              </PortfolioViewProject>
            </CardDescWrap>
          </PortfolioCard>
          </CardWrapper>

        </Carousel>
      </PortfolioContainer>
    </>
  );
};

export default PortfolioSection;
