import React from "react";
import { FaLinkedin, FaGithub, FaFileDownload } from "react-icons/fa";
import {
  SocialIconLink,
  SocialIcons,
  SocialMedia,
  SocialMediaWrap,
  WebsiteRights,
  FooterContainer,
  FooterWrap,
} from "./FooterElements";

const Footer = () => {
  return (
    <FooterContainer>
      <FooterWrap>
        <SocialMedia>
          <SocialMediaWrap>
            <SocialIcons>
              <SocialIconLink
                href="https://www.linkedin.com/in/rafael-carrilho-615206179/"
                target="_blank"
                aria-label="Linkedin"
              >
                <FaLinkedin />
              </SocialIconLink>
              <SocialIconLink
                href="https://github.com/carrilhorafael57"
                target="_blank"
                aria-label="Github"
              >
                <FaGithub />
              </SocialIconLink>
              <SocialIconLink
                href="/Assets/resume.pdf"
                download={"RafaelCarrilho_Resume.pdf"}
                target="_blank"
                aria-label="Resume Download"
              >
                <FaFileDownload />
              </SocialIconLink>
            </SocialIcons>
          </SocialMediaWrap>
        </SocialMedia>
        <WebsiteRights>
          Rafael Carrilho &copy; {new Date().getFullYear()}. All Rights
          reserved.
        </WebsiteRights>
      </FooterWrap>
    </FooterContainer>
  );
};

export default Footer;
